import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

import { ContactForm, ContentBlock, Headline, Text } from '../components';

import { black } from '../utils/sharedStyles';

import facebook from '../assets/svgs/main-facebook-standard.svg';
import instagram from '../assets/svgs/main-insta-standard.svg';
import mail from '../assets/svgs/mail-icon.svg';

const Name = styled.p`
  font: normal normal 600 22px/26px Heebo;
`;

const Location = styled.p`
  font: normal normal 300 22px/26px Heebo;
`;

const SocialWrapper = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
`;

const SocialIcon = styled.img`
  width: 20px;
  max-height: 20px;
`;

const SocialLink = styled.a`
  padding-left: 0.5rem;
  color: ${black};
  font: normal normal normal 22px/21px Heebo;
`;

const Contact = () => (
  <ContentBlock pb={8}>
    <Headline semiBold>Let's get in touch</Headline>
    <Text>
      I'm looking forward to meet you guys and hear your stories! If you want to
      work, talk or just get in touch with me, write an e-mail or use the
      contact form below.
    </Text>
    <Box my={3}>
      <Name>Vassili Ekoutsidis</Name>
      <Location>Berlin/Germany</Location>
    </Box>
    <Box mb={8}>
      <SocialWrapper>
        <SocialIcon src={mail} />
        <SocialLink href="mailto:hello@ekoutstudio.com">
          hello@ekoutstudio.com
        </SocialLink>
      </SocialWrapper>
      <SocialWrapper>
        <SocialIcon src={instagram} />
        <SocialLink
          href="https://www.instagram.com/ekoutstudio"
          target="_blank"
          rel="noreferrer"
        >
          instagram.com/ekoutstudio
        </SocialLink>
      </SocialWrapper>
    </Box>
    <ContactForm />
  </ContentBlock>
);

export default Contact;
